import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRegEye, FaPen, FaTrash, FaPlus, FaTools, FaGlobeAmericas } from "react-icons/fa";

import LocationCreate from "./components/LocationCreate";
import LocationView from "./components/LocationView";
import LocationDelete from "./components/LocationDelete";
import { TABLE_CONFIGS } from "./constants";
import { useUser } from "../../utilities/helper";
import { ROLES } from "../../utilities/constants";

function LocationsConfig() {
  const [locations, setLocations] = useState([]);
  const [createMode, setCreateMode] = useState(false);
  const [viewIndex, setViewIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const { user } = useUser();
  let locationHeaders = [...TABLE_CONFIGS];

  if (user.is_CS_role) {
    locationHeaders = TABLE_CONFIGS.filter(
      (item) => !["ach_enabled", "coming_soon", "sboa", "sboa_brochure"].includes(item.key)
    );
  }

  const fetchLocations = () => {
    axios.get("/api/locations?role=admin").then((res) => {
      setLocations(res.data || []);
    });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-title">
        <div className="row">
          <div className="col-sm-4">
            <h2>
              <b>Locations</b>
            </h2>
          </div>
          <div className="col-sm-8">
            <div className="d-flex justify-content-end">
              <a className="btn btn-success add-new mx-3" href="/locations_bulk_edit">
                Bulk CMS Update
              </a>
              <button type="button" className="btn btn-info add-new" onClick={() => setCreateMode(true)}>
                <FaPlus /> Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <div className="table-wrapper">
          <table className="table table-striped table-hover table-bordered bg-white custom-table">
            <thead>
              <tr>
                {locationHeaders.map((header) => (
                  <th style={{ minWidth: header.minWidth }} key={header.key} className={header.className || null}>
                    {header.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {locations.map((location, index) => (
                <tr key={location.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{location.location_code}</td>
                  <td>{location.name}</td>
                  <td>{location.phone}</td>
                  <td>{location.address}</td>
                  <td>{location.city}</td>
                  <td>{location.zip}</td>
                  <td>{location.state}</td>
                  <td>{location.slug}</td>
                  <td>{location.w9}</td>
                  {!user.is_CS_role && (
                    <>
                      <td>{location.allow_ach && "Yes"}</td>
                      <td>{location.coming_soon && "Yes"}</td>
                      <td>{location.sboa && "Yes"}</td>
                      <td>{location.sboa_brochure}</td>
                      <td className="text-center actions">
                        <span className="action-btn text-success px-1" title="View" onClick={() => setViewIndex(index)}>
                          <FaRegEye />
                        </span>
                        {user.role.superadmin && (
                          <span
                            className="action-btn text-warning px-1"
                            title="Edit"
                            onClick={() => setEditIndex(index)}
                          >
                            <FaPen />
                          </span>
                        )}
                        {user.role.superadmin && (
                          <span
                            className="action-btn text-danger px-1"
                            title="Delete"
                            onClick={() => setDeleteIndex(index)}
                          >
                            <FaTrash />
                          </span>
                        )}
                        {location.facility_map_enabled && (
                          <Link
                            className="action-btn text-success px-1"
                            title="Facility Map"
                            to={{ pathname: `/locations/${location.location_code}/map_edit` }}
                          >
                            <FaGlobeAmericas />
                          </Link>
                        )}
                      </td>
                    </>
                  )}
                  <td className="text-center actions">
                    <Link
                      className="action-btn text-success px-1"
                      title="Edit CMS Content"
                      to={{
                        pathname: `/locations/${location.location_code}/cms_edit`,
                        state: { locationId: location.id },
                      }}
                    >
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {createMode && (
        <LocationCreate mode="create" onClose={() => setCreateMode(false)} onFinish={() => fetchLocations()} />
      )}
      {viewIndex > -1 && <LocationView location={locations[viewIndex]} onClose={() => setViewIndex(-1)} />}
      {editIndex > -1 && (
        <LocationCreate
          mode="edit"
          location={locations[editIndex]}
          onClose={() => setEditIndex(-1)}
          onFinish={() => fetchLocations()}
        />
      )}
      {deleteIndex > -1 && (
        <LocationDelete
          location={locations[deleteIndex]}
          onClose={() => setDeleteIndex(-1)}
          onFinish={() => fetchLocations()}
        />
      )}
    </div>
  );
}

export default LocationsConfig;
