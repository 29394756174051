import React, { useEffect, useState } from "react";
import axios from "axios";
import { getLocation, locationsInRange } from "../../utilities/common";
import SnapShotHeader from "../../components/SnapShotHeader";
import { SNAPSHOT_DETAIL_KEYS } from "../../utilities/constants";
import WayFinding from "./components/WayFinding";
import { getCmsLink } from "../../utilities/helper";
import { Modal, BreadCrumb } from "../../components";
import "./style.css";
import {
  titleComponents,
  locationInfos,
  gateAccess,
  propertyInfos,
  unitAmenities,
  billingAndInsurance,
  timezonesInUS,
} from "./constants";
import { Helmet } from "react-helmet";
import { PATH } from "../../routers";
import Loader from "../../components/Loader";
import { useSnapShotUser } from "../../utilities/helper";
const moment = require("moment");

const initialValue = {
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ALERTS]: "",
  // Title
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ADDRESS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_CITY]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_STATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ZIP]: "",
  // Location info
  [SNAPSHOT_DETAIL_KEYS.FACILITY_NICKNAME]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PRONUNCIATION]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_COUNT]: 0,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PHONE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TIMEZONE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_LANDMARK]: "",
  // Property info
  [SNAPSHOT_DETAIL_KEYS.FACILITY_DATE_OF_ACQUISITION]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PREVIOUS_OWNER]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_KIOSK]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_GATE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_FENCE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_CAMERAS]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TRUCK_ACCESS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_FLOOR_COUNT]: 0,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ELEVATOR_TYPE]: "",
  // Gate access
  [SNAPSHOT_DETAIL_KEYS.FACILITY_ACCESS_HOURS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_EMERGENCY_CODE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_TEMP_CODE]: "",
  // Unit Amenities
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_HEIGHT]: "",
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_UNITS]: false,
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_TYPE]: "",
  [SNAPSHOT_DETAIL_KEYS.CLIMATE_CONTROLLED_RANGE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_RV_RANKING]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INDOOR_PARKING]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_OUTDOOR_PARKING]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_OUTSIDE_UNITS]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_SURFACE]: "",
  // Unique Detail
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIQUE_DETAILS]: "",
  // Billing and Insurance
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PROTECTION]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_INSURANCE_LEVELS]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX]: false,
  [SNAPSHOT_DETAIL_KEYS.FACILITY_UNIT_TAX_RATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_PARKING_TAX_RATE]: "",
  [SNAPSHOT_DETAIL_KEYS.FACILITY_LATE_FEE_SCHEME]: "",

  [SNAPSHOT_DETAIL_KEYS.PROPERTY_MAP]: "",
};

const SnapShotDetail = (props) => {
  const [data, setData] = useState(initialValue);
  const [visibleModal, setVisibleModal] = useState(false);
  const [serialNumber, setSerialNumber] = useState(null);
  const [comboCode, setComboCode] = useState(null);
  const [modalNearbyFacilitiesVisible, setModalNearbyFacilitiesVisible] = useState(false);
  const [modalNearbyFacilitiesLoading, setModalNearbyFacilitiesLoading] = useState(false);
  const [nearByFacilities, setNearByFacilities] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [gateCodes, setGateCodes] = useState({});
  const { user, selectedPortfolioId } = useSnapShotUser();

  useEffect(() => {
    axios
      .get("/api/gate_code_rotation/gate_codes")
      .then((res) => {
        setGateCodes(
          res.data.reduce((prev, curr) => {
            return { ...prev, [curr.name]: curr };
          }, {})
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchComboCode = async () => {
    setFetching(true);
    return axios
      .get("/api/snapshot_locations/combo_code", {
        params: {
          serial_number: serialNumber,
        },
      })
      .then((result) => {
        if (result.data) {
          setComboCode(result.data.combo_code);
        }
      })
      .catch((error) => {
        alert(error.response?.data?.message);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const {
    match: {
      params: { slug },
    },
  } = props;
  const location = getLocation(slug);
  const facility_id = location.id;

  useEffect(() => {
    if (facility_id) {
      axios
        .get("/api/snapshot_locations", { params: { facility_id } })
        .then((result) => {
          if (result.data) setData(result.data);
        })
        .catch((error) => console.log("error", error));
    }
  }, []);

  const getSelectValue = (value) => (value ? "Yes" : "No");

  const renderListItems = (listItems = [], type = null) => {
    return listItems.map((item) => {
      const { label, key } = item;
      if (type == "gateAccess") {
        return (
          <p key={key} className="m-0">
            {label}: <span className="text-bold">{data[key]}</span>
          </p>
        );
      } else {
        if (item.isSelectTimezones) {
          const selectedTimezone = timezonesInUS.find((tz) => tz.name === data[key]);
          if (!selectedTimezone) {
            return null;
          }
          const currentTimeWithUTC = moment()
            .utcOffset(selectedTimezone.seconds / 60)
            .format("hh:mm A");
          return (
            <p key={key} className="m-0">
              {"Current time"}: <span className="text-bold">{currentTimeWithUTC}</span>
            </p>
          );
        }
        const value = item.isSelect ? getSelectValue(data[key]) : data[key];
        return (
          <p key={key} className="m-0">
            {label}: <span className="text-bold">{value}</span>
          </p>
        );
      }
    });
  };

  const handleLoadNearbyFacilities = (e) => {
    e.preventDefault();
    setModalNearbyFacilitiesVisible(true);
    setModalNearbyFacilitiesLoading(true);
    axios
      .post("/api/rafa/locations", {
        portfolioId: selectedPortfolioId,
        email: user.email,
        token: user.user_token,
      })
      .then((result) => {
        setModalNearbyFacilitiesLoading(false);
        const locations = result.data;
        const location = locations.find((location) => location.id?.toString() === slug);
        const nearByFacilities = locationsInRange(locations, location.lat, location.lng);
        setNearByFacilities(nearByFacilities);
      })
      .catch((error) => {
        setModalNearbyFacilitiesLoading(false);
        alert(error);
        setNearByFacilities([]);
      });
  };

  const viewTask = () => {
    props.history.push(PATH.SNAPSHOT_FACILITY_TASKS + `/${facility_id}`);
  };
  const viewCommunicationLog = () => {
    props.history.push(PATH.SNAPSHOT_FACILITY_COMMUNICATION_LOG + `/${facility_id}`);
  };

  const createNewTask = () => {
    props.history.push(PATH.SNAPSHOT_NEW_TASK + `/${facility_id}`);
  };

  const editFacility = () => {
    props.history.push(PATH.ADMIN_SNAPSHOT + `/${facility_id}/edit`);
  };

  return (
    <div>
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <SnapShotHeader />
      <div className="bg-light">
        <div className="row justify-content-between pt-2">
          <div className="col-9 ml-2">
            <BreadCrumb title="Search" path={PATH.CSR_MAIN} />
          </div>
          <div className="col-2">
            <button id="combo-code-btn" className="btn mr-2" onClick={() => setVisibleModal(true)}>
              Get Combo Code
            </button>
            {user.is_admin && (
              <button id="edit-facility-btn" className="btn mr-2" onClick={editFacility}>
                Edit
              </button>
            )}
          </div>
        </div>
        <div className="col-10 mx-auto min-vh-100">
          <div className="row align-items-center">
            <p className={"title mr-2"}>{location.name}</p>
            <p className={"separator"}>|</p>
            <div className="d-flex align-items-center">
              <p className={"title-block m-0 mx-1"}>
                {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
              </p>
            </div>
          </div>
          {data.facility_alerts && (
            <div className="row align-items-center">
              <p className={"title mr-2"}>
                <div className="facility-alerts">{data.facility_alerts}</div>
              </p>
            </div>
          )}
          <div className="row mt-5">
            <div className="col-3">
              <p className={"title-block"}>Location Information</p>
              {renderListItems(locationInfos)}
            </div>

            <div className="col-3">
              <p className={"title-block"}>Gate Access</p>
              {renderListItems(gateAccess, "gateAccess")}
            </div>

            <div className="col-3">
              <p className={"title-block"}>Unique Details</p>
              <pre>{data[SNAPSHOT_DETAIL_KEYS.FACILITY_UNIQUE_DETAILS]}</pre>
            </div>

            <div className="col-3">
              <p className="title-block">Wayfinding</p>
              <WayFinding
                googleURL={`http://maps.google.com/?q=sparebox storage ${location.address} ${location.state} ${location.zip}&z=22&t=k`}
                propertyMapURL={data[SNAPSHOT_DETAIL_KEYS.PROPERTY_MAP_SIGNED_URL]}
                mapEditURL={`/snapshot/${slug}/map`}
                websiteURL={`${getCmsLink()}/storage-units/${location.state.toLowerCase()}/${location.city.toLowerCase()}/${facility_id}`}
                allowEdit={false}
                openNearbyFacilitiesModal={handleLoadNearbyFacilities}
              />
            </div>

            <div className="col-3">
              <p className={"title-block"}>Property Information</p>
              {renderListItems(propertyInfos)}
            </div>

            <div className="col-3">
              <p className={"title-block"}>Unit Amenities</p>
              {renderListItems(unitAmenities)}
            </div>

            <div className="col-3">
              <p className={"title-block"}>Billing and Insurance</p>
              {renderListItems(billingAndInsurance)}
            </div>

            <div className="col-3 mt-4">
              {/* <button className="d-block btn btn-primary font-weight-bold mb-2" onClick={viewCommunicationLog}>
                Communication Log
              </button> */}
              <button className="d-block btn btn-primary view-task-btn mb-2" onClick={viewTask}>
                View Tasks
              </button>
              <button className="d-block btn btn-primary view-task-btn" onClick={createNewTask}>
                Create New Task
              </button>
            </div>
          </div>

          <Modal visible={visibleModal} setVisible={() => setVisibleModal(false)} title="Combo Code">
            <input
              placeholder={"Enter serial number"}
              className="form-control"
              onChange={(e) => setSerialNumber(e.target.value)}
            />
            <button className="btn btn-success my-2" onClick={fetchComboCode} disabled={fetching}>
              Get Combo Code
            </button>
            {comboCode && <p>Your combo code is: {comboCode}</p>}
          </Modal>
          <Modal
            visible={modalNearbyFacilitiesVisible}
            setVisible={() => setModalNearbyFacilitiesVisible(false)}
            title="Nearby Facilities"
          >
            {modalNearbyFacilitiesLoading ? (
              <div className="row justify-content-center my-3">
                <Loader />
              </div>
            ) : (
              <ul>
                {nearByFacilities.length === 0 && "No facilities within 10 miles"}
                {nearByFacilities.map((location) => (
                  <li key={location.id}>
                    <a href={`/snapshot/${facility_id}`} target="_blank" rel="noreferrer">
                      {`${location.name}, ${location.city}, ${location.state}, Distance: ${location.distance?.toFixed(
                        2
                      )} mile`}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SnapShotDetail;
