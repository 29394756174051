import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BASE_URL_ENV, getShortRole } from "../SnapshotFacilityTasks/constants";
import styles from "./styles.module.css";
import axios from "axios";
import { getFacilityTitle, useSnapShotUser } from "../../../utilities/helper";
import getAxiosWithDefaultHeaders from "../config";
import moment from "moment";
import Pagination from "rc-pagination";
import { getLocationList } from "../../../utilities/common";

const TABLE_FIELDS = [
  {
    header: "ID",
    key: "id",
  },
  {
    header: "Sent Date",
    key: "created_at",
  },
  {
    header: "Unit Number",
    key: "unit_no",
  },
  {
    header: "Tenant Name",
    key: "tenant_name",
  },
  {
    header: "Template Name",
    key: "template_name",
  },
  {
    header: "Method",
    key: "send_method",
  },
  {
    header: "Status",
    key: "status",
  },
];
const PAGINATION_PAGE_SIZE = 10;
const PAGINATION_CURRENT_PAGE = 1;

const locale = {
  items_per_page: "/ page",
  jump_to: "Go to",
  jump_to_confirm: "confirm",
  page: "Page",

  // Pagination.jsx
  prev_page: "Previous Page",
  next_page: "Next Page",
  prev_5: "Previous 5 Pages",
  next_5: "Next 5 Pages",
  prev_3: "Previous 3 Pages",
  next_3: "Next 3 Pages",
  page_size: "Page Size",
};

export default function SnapshotCommunicationLog(props) {
  const { match: { params: { site_code } } } = props;
  const locations = getLocationList();
  const location = locations.find((location) => location.location_code === site_code);
  const facility_id = location?.id;

  const { user, selectedPortfolioId } = useSnapShotUser();
  let [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [currentSort, setCurrentSort] = useState(() => {
    return JSON.parse(localStorage.getItem("current-sort")) || { facility_name: "sort-up" };
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [currentPage, setCurrentPage] = useState(PAGINATION_CURRENT_PAGE);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const Axios = getAxiosWithDefaultHeaders();
  const fetchSnapshotTasksOverview = async (page) => {
    setIsLoading(true);
    try {
      const response = await Axios.get(
        `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tenant_message?facility_id=${facility_id}&page=${page}&per_page=${PAGINATION_PAGE_SIZE}`,
      );
      const data = response.data.entries;
      setTotalEntries(response.data.total_entries);
      setTotalPages(response.data.total_pages);
      // const results = customSort(data, Object.keys(currentSort)[0], Object.values(currentSort)[0]);
      setDataList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchSnapshotTasksOverview(currentPage);
  }, [currentPage]);

  const toggleSort = (key) => {
    let currSort = currentSort[key] || "sort";
    if (currSort === "sort") {
      currSort = "sort-up";
    } else if (currSort === "sort-up") {
      currSort = "sort-down";
    } else if (currSort === "sort-down") {
      currSort = "sort-up";
    }
    setCurrentSort({ [key]: currSort });
    const sortedTasks = customSort(tasks, key, currSort);
    setTasks(sortedTasks);
    localStorage.setItem("current-sort", JSON.stringify({ [key]: currSort }));
  };

  const customSort = (items, key, currSort) => {
    const sortedItems = [...items];
    sortedItems.sort((t1, t2) => {
      if (key === "facility_name") {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);

        if (isNaN(siteNum1) || isNaN(siteNum2)) {
          const name1 = t1.facility_name.toLowerCase();
          const name2 = t2.facility_name.toLowerCase();
          return currSort === "sort-up" ? name1.localeCompare(name2) : name2.localeCompare(name1);
        }

        const sortIndex = currSort === "sort-up" ? siteNum1 - siteNum2 : siteNum2 - siteNum1;
        return sortIndex;
      }
      if (key === "assigned_to") {
        return currSort === "sort-up"
          ? t1.assigned_to > t2.assigned_to
            ? 1
            : -1
          : t1.assigned_to > t2.assigned_to
          ? -1
          : 1;
      }
      if (key === "open_highest_priority") {
        const priorityOrder = ["Urgent", "High", "Medium", "Low", ""];
        return currSort === "sort-up"
          ? priorityOrder.indexOf(t1.open_highest_priority) < priorityOrder.indexOf(t2.open_highest_priority)
            ? 1
            : -1
          : priorityOrder.indexOf(t1.open_highest_priority) < priorityOrder.indexOf(t2.open_highest_priority)
          ? -1
          : 1;
      }
      return currSort === "sort-up" ? parseInt(t1[key]) - parseInt(t2[key]) : parseInt(t2[key]) - parseInt(t1[key]);
    });
    return sortedItems;
  };

  const getAssignedToRole = (roles) => {
    if (!roles) return "";
    return roles
      .split(", ")
      .map((role) => getShortRole(role))
      .join(", ");
  };

  const titlePage = "Communication Logs";

  return (
    <div>
      <Helmet>
        <title>{titlePage}</title>
      </Helmet>
      <SnapShotHeader />

      <div className="col-12 bg-light min-vh-100">
        <div className="col-11 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className={styles.topHeader}>{titlePage}</p>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {TABLE_FIELDS.map((field) => (
                  <th
                    key={field.key}
                    scope="col"
                    onClick={() => toggleSort(field.key)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {field.header} &nbsp;
                    <i className={`fa-solid fa-${currentSort[field.key] || "sort"}`}></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {!isLoading && dataList.length !== 0
                ? dataList.map((item) => (
                    <tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td className="text-right">{moment(item.created_at).format("YYYY-MM-DD")}</td>
                      <td className="text-right">{item.unit_no}</td>
                      <td className="text-right">
                        {item.tenant?.first_name} {item.tenant?.last_name}
                      </td>
                      <td className={`text-right`}>{item.template.name}</td>
                      <td className={`text-right`}>{item.send_method}</td>
                      <td className={`text-right`}>{item.status}</td>
                    </tr>
                  ))
                : null}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={9} className="text-right">
                  <Pagination
                    current={currentPage}
                    onChange={onPageChange}
                    pageSize={PAGINATION_PAGE_SIZE}
                    total={totalEntries}
                    hideOnSinglePage
                    locale={locale}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
