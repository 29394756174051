import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BASE_URL_ENV, getShortRole } from "../SnapshotFacilityTasks/constants";
import styles from "./styles.module.css";
import axios from "axios";
import { getFacilityTitle, useSnapShotUser } from "../../../utilities/helper";

const TABLE_FIELDS = [
  {
    header: "Facility",
    key: "facility_name",
  },
  {
    header: "Open Facility Tasks",
    key: "open_facility_tasks_count",
  },
  {
    header: "Open Unit Tasks",
    key: "open_unit_tasks_count",
  },
  {
    header: "Priority",
    key: "open_highest_priority",
  },
  {
    header: "Oldest Task",
    key: "oldest_task_in_day",
  },
  {
    header: "Max Inactivity",
    key: "max_inactivity_in_day",
  },
  {
    header: "Assigned To",
    key: "assigned_to",
  },
];

function SnapshotTasksOverview(props) {
  let [tasks, setTasks] = useState([]);
  let [unfilteredTasks, setUnfilteredTasks] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [currentSort, setCurrentSort] = useState(() => {
    return JSON.parse(localStorage.getItem("current-sort")) || { facility_name: "sort-up" };
  });
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const { user, selectedPortfolioId } = useSnapShotUser();

  const getSiteNumber = (facilityName) => {
    return parseInt(facilityName.split(" ").pop());
  };

  const fetchSnapshotTasksOverview = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "X-User-Email": user.email,
          "X-User-Token": user.user_token,
        },
      };
      const response = await axios.get(
        `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks/overview?portfolio_id=${selectedPortfolioId}`,
        config
      );

      const activeLocations = response.data.filter((e) => !e.hide_in_snapshot);
      const results = customSort(activeLocations, Object.keys(currentSort)[0], Object.values(currentSort)[0]);
      setTasks(results);
      setUnfilteredTasks(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  const filterSearchResult = async (unfilteredTasks, search) => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "X-User-Email": user.email,
          "X-User-Token": user.user_token,
        },
      };
      const result = await axios.get(
        `${
          BASE_URL_ENV[process.env.REACT_APP_ENV]
        }/api/tasks/search?portfolio_id=${selectedPortfolioId}&query=${search}`,
        config
      );
      const filterTasks = customSort(unfilteredTasks, Object.keys(currentSort)[0], Object.values(currentSort)[0]);
      setTasks(filterTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFilterSearchResult = useCallback(
    debounce((unfilteredTasks, search) => {
      if (search) {
        filterSearchResult(unfilteredTasks, search);
      } else {
        fetchSnapshotTasksOverview();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    fetchSnapshotTasksOverview();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    debouncedFilterSearchResult(unfilteredTasks, search);
  }, [search]);

  const toggleSort = (key) => {
    let currSort = currentSort[key] || "sort";
    if (currSort === "sort") {
      currSort = "sort-up";
    } else if (currSort === "sort-up") {
      currSort = "sort-down";
    } else if (currSort === "sort-down") {
      currSort = "sort-up";
    }
    setCurrentSort({ [key]: currSort });
    const sortedTasks = customSort(tasks, key, currSort);
    setTasks(sortedTasks);
    localStorage.setItem("current-sort", JSON.stringify({ [key]: currSort }));
  };

  const customSort = (items, key, currSort) => {
    const sortedItems = [...items];
    sortedItems.sort((t1, t2) => {
      if (key === "facility_name") {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);

        if (isNaN(siteNum1) || isNaN(siteNum2)) {
          const name1 = t1.facility_name.toLowerCase();
          const name2 = t2.facility_name.toLowerCase();
          return currSort === "sort-up" ? name1.localeCompare(name2) : name2.localeCompare(name1);
        }

        const sortIndex = currSort === "sort-up" ? siteNum1 - siteNum2 : siteNum2 - siteNum1;
        return sortIndex;
      }
      if (key === "assigned_to") {
        return currSort === "sort-up"
          ? t1.assigned_to > t2.assigned_to
            ? 1
            : -1
          : t1.assigned_to > t2.assigned_to
          ? -1
          : 1;
      }
      if (key === "open_highest_priority") {
        const priorityOrder = ["Urgent", "High", "Medium", "Low", ""];
        return currSort === "sort-up"
          ? priorityOrder.indexOf(t1.open_highest_priority) < priorityOrder.indexOf(t2.open_highest_priority)
            ? 1
            : -1
          : priorityOrder.indexOf(t1.open_highest_priority) < priorityOrder.indexOf(t2.open_highest_priority)
          ? -1
          : 1;
      }
      return currSort === "sort-up" ? parseInt(t1[key]) - parseInt(t2[key]) : parseInt(t2[key]) - parseInt(t1[key]);
    });
    return sortedItems;
  };

  const getAssignedToRole = (roles) => {
    if (!roles) return "";
    return roles
      .split(", ")
      .map((role) => getShortRole(role))
      .join(", ");
  };

  const goBackHomeView = () => props.history.push("/");

  return (
    <div>
      <Helmet>
        <title>Tasks Overview</title>
      </Helmet>
      <SnapShotHeader />

      <div className="col-12 bg-light min-vh-100">
        <div className="col-11 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className={styles.topHeader}>Task Overview</p>
            <button className="btn btn-primary" onClick={goBackHomeView}>
              Property Profiles
            </button>
          </div>
          <div className="input-group mt-4 mb-4">
            <input
              className="form-control py-2 border-right-0 border"
              type="search"
              value={search}
              placeholder="Search by Unit Number or Task Type..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="input-group-append">
              <button className="btn btn-outline-secondary border-left-0 border" type="button">
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {TABLE_FIELDS.map((field) => (
                  <th
                    key={field.key}
                    scope="col"
                    onClick={() => toggleSort(field.key)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {field.header} &nbsp;
                    <i className={`fa-solid fa-${currentSort[field.key] || "sort"}`}></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {!isLoading && tasks.length !== 0
                ? tasks.map((task) => (
                    <tr key={task.facility_id}>
                      <th scope="row">
                        <Link to={`/snapshot/facility-tasks/${task.facility_id}`}>
                          {getFacilityTitle(task.facility_name, task.facility_store_number)}
                        </Link>
                      </th>
                      <td className="text-right">{task.open_facility_tasks_count}</td>
                      <td className="text-right">{task.open_unit_tasks_count}</td>
                      <td className={`text-right days-old ${task.open_highest_priority === "Urgent" ? "warning" : ""}`}>
                        {task.open_highest_priority}
                      </td>
                      <td className={`text-right days-old ${task.oldest_task_in_day >= 7 ? "warning" : ""}`}>
                        {task.oldest_task_in_day + " Days"}
                      </td>
                      <td className={`text-right days-old ${task.max_inactivity_in_day >= 7 ? "warning" : ""}`}>
                        {task.max_inactivity_in_day + " Days"}
                      </td>
                      <td className="text-left">{task.assigned_to && getAssignedToRole(task.assigned_to)}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SnapshotTasksOverview;
