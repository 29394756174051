import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../../src/styles/pagination.css";
import { BreadCrumb } from "../../../components";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { PATH } from "../../../routers";
import { getLocation, locale } from "../../../utilities/common";
import { titleComponents } from "../../SnapShotDetail/constants";
import { BASE_URL_ENV, dateDiff, getBackgroundColor, PAGE_SIZE, priorOptions, ROLES_LABEL_COLORS, TASK_CATEGORIES, WARNING_DAYS_OLD } from "./constants";
import { useFetchSnapshotFacilityTasks } from "./hook";
import "./styles.css";
import getAxiosWithDefaultHeaders from "../config";

function SnapshotFacilityTasks(props) {
  const {
    match: {
      params: { site_code },
    },
  } = props;
  const location = getLocation(site_code);
  const facility_id = location?.id || ''

  if (!location) {
    props.history.replace("/404");
    return null;
  }

  const [isLoadingCategory, tasksCategory, currentPageCategory, setCurrentPageCategory, totalItemsCategory] =
    useFetchSnapshotFacilityTasks(location?.id, false, TASK_CATEGORIES[0].category);

  const [isLoadingUnit, tasksUnit, currentPageUnit, setCurrentPageUnit, totalItemsUnit] = useFetchSnapshotFacilityTasks(
    location.id,
    false,
    TASK_CATEGORIES[1].category
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskActivities, setTaskActivities] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const Axios = getAxiosWithDefaultHeaders();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      Axios.get(`/api/task_activities?facility_id=${facility_id}&page=${page}`)
        .then((res) => {
          setTaskActivities([...taskActivities, ...res.data.entries]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setTaskActivities([]);
      setCurrentPage(1);
    }
  }, [isOpen, site_code, page]);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const closePanel = () => {
    setIsOpen(false);
  };

  const loadMore = () => {
    setCurrentPage((page) => page + 1);
  };

  const renderTasks = (category) => {
    const isLoading = category.category === "unit" ? isLoadingUnit : isLoadingCategory;
    const tasks = category.category === "unit" ? tasksUnit : tasksCategory;
    return (
      <div className="row col-12" key={category.category}>
        <h2 className="task-type-title">{category.title}</h2>
        <div className="row col-12 d-flex flex-wrap mt-4 mb-4">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary ml-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null}
          {!isLoading && tasks.length
            ? tasks.map((task) => <Task key={task.id} task={task} site_code={site_code} />)
            : null}
          {!isLoading && !tasks.length ? <p className="ml-2">{"No task available"}</p> : null}
        </div>
      </div>
    );
  };

  const renderPagination = (category) => {
    if (category.category === "unit") {
      return (
        <div className="rol col-12 d-flex justify-content-center mt-4">
          <Pagination
            current={currentPageUnit}
            onChange={setCurrentPageUnit}
            total={totalItemsUnit}
            pageSize={PAGE_SIZE}
            locale={locale}
          />
        </div>
      );
    }
    return (
      <div className="rol col-12 d-flex justify-content-center mt-4">
        <Pagination
          current={currentPageCategory}
          onChange={setCurrentPageCategory}
          total={totalItemsCategory}
          pageSize={PAGE_SIZE}
          locale={locale}
        />
      </div>
    );
  };

  const getTaskAction = (action) => {
    const actions = {
      create: "created task",
      note: "added note",
      assign: "reassigned task",
      reopen: "re-opened task",
      close: "closed task",
    };
    return actions[action];
  };

  return (
    <div className="bg-light">
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <SnapShotHeader />
      <div>
        <div className="row justify-content-between pt-2">
          <div className="col-9 ml-2">
            <BreadCrumb title="Task Overview" path={PATH.SNAPSHOT_TASK_OVERVIEW} />
          </div>
        </div>
      </div>
      <div className="col-10 mx-auto min-vh-100">
        <div className="row align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            <p className={"title mr-2"}>{location.name}</p>
            <p className={"separator"}>|</p>
            <h1 className={"title-block m-0 mx-1"}>
              {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
            </h1>
          </div>

          <div className="form-group d-flex flex-column">
            <button
              className="btn btn-primary mb-2"
              onClick={() => props.history.push(`/snapshot/new-task/${site_code}`)}
            >
              Create New Task
            </button>
            <button
              className="btn btn-outline-primary mb-2"
              onClick={() => props.history.push(`/snapshot/closed-task/${site_code}`)}
            >
              Archive
            </button>
            <button className="btn btn-outline-primary mb-2" onClick={togglePanel}>
              Activity
            </button>
            <button className="btn btn-outline-primary mb-2" onClick={() => props.history.replace(`/snapshot/${facility_id}`)}>
              Property Profiles
            </button>
          </div>
        </div>
        <div className="row mt-4">
          {TASK_CATEGORIES.map((category) => (
            <>
              {renderTasks(category)}
              {renderPagination(category)}
            </>
          ))}
        </div>
      </div>
      <div className={`slide-panel${isOpen ? " open" : ""}`}>
        <div className={`close-btn-container${isOpen ? " open" : ""}`}>
          <button className={`btn btn-link close-btn`} onClick={closePanel}>
            Close
          </button>
        </div>
        <div className="panel-content">
          <h3 className="p-2">Activity Log</h3>
          {isLoading && taskActivities.length === 0 ? <Skeleton count={10} height={45} /> : null}
          {taskActivities.map((taskActivity) => (
            <div key={taskActivity.id} className="p-2">
              <p>
                <strong>
                  {taskActivity.source_username
                    ? `${taskActivity.source_username} `
                    : `${taskActivity.source_user_email} `}
                </strong>
                {`${getTaskAction(taskActivity.action)}: ` +
                  `${taskActivity.task_type}` +
                  `${taskActivity.unit_no ? ", " + taskActivity.unit_no : ""} | ` +
                  `${new Date(taskActivity.created_at).toLocaleString()}`}
              </p>
            </div>
          ))}
          {isLoading && taskActivities.length > 0 ? <Skeleton count={10} height={45} /> : null}
          {!isLoading && taskActivities.length > 0 ? (
            <button className="btn btn-link" onClick={loadMore}>
              {"Load more..."}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function Task({ task, site_code }) {
  let oldDate = task.created_at;
  let newDate = new Date();
  if (task.status === "closed") {
    newDate = task.closed_at;
  }
  const numDays = dateDiff(oldDate, newDate);
  const priorityInfo = priorOptions.filter((option => option.value === task.prior))
  return (
    <Link key={task.id} className="task-card col-3" to={`/snapshot/task-details/${site_code}/${task.id}`}>
      <div className="task-title">
        {task.task_type.category === "unit" ? task.unit_no : task.task_type.title}
        {task.task_type.category === "unit" ? <div className="task-description">{task.task_type.title}</div> : null}
        <div className="task-description text-ellipses">{task.description}</div>
      </div>
      <div className="d-flex flex-column">
        {
          priorityInfo.length > 0 &&
          <div style={{ color: priorityInfo[0].color, fontWeight: priorityInfo[0]?.style?.fontWeight, fontSize: 12 }}>
            {priorityInfo[0].label1}
          </div>
        }
        <div className="task-footer d-flex justify-content-between">
          <div
            className={
              "days-old" + (dateDiff(task.created_at) >= WARNING_DAYS_OLD && task.status !== "closed" ? " warning" : "")
            }
          >
            {numDays > 1 ? numDays + " days old" : numDays + " day old"}
          </div>
          <div className="role">
            <div style={{ backgroundColor: getBackgroundColor(task.role || task.source_user_title) }} className="avatar">
              {(task.role || task.source_user_title)?.split("_").map((word) => word[0]).join("").slice(0, 2).toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SnapshotFacilityTasks;
