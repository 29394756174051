import { API_ENDPOINT } from "../utilities/constants";
import axios from "axios";

const getSnapShotDetail = async (facility_id) => {
  const response = await axios.get(API_ENDPOINT.SNAPSHOT, { params: { facility_id } });
  return response.data;
};

const saveSnapShotDetail = async (data) => {
  return axios.post(API_ENDPOINT.SNAPSHOT, data);
};
export default { getSnapShotDetail, saveSnapShotDetail };
